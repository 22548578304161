.panel__stats {
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint(sp) {
    display: block;
    text-align: center;
  }
}

.panel__breakdown {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  margin-left: 10%;
  flex-wrap: wrap;
  @include breakpoint(sp) {
    display: inline;
  }
  &--item {
    padding: 10px;
    width: 100%;
  }
}

.panel__title-sec {
  font-family: $raleway;
  font-size: 15px;
  color: #000;
  font-weight: bold;
}

.userPerUserTypeCont {
  width: 100%;
  height: 500px;
}

.downloads .panel {
  margin: 8px 0;
}

.isFullScreen {
  width: 100vw;
  height: 100vh;
}

.fullscreen-title {
  display: flex;
  justify-content: space-between;
}

.imageFullscreen {
  height: 100vh;
}

.imageContainer {
  position: relative;
}

.fullscreenButton {
  position: absolute;
  top: 8px;
  right: 16px;
}