$raleway: "Raleway", sans-serif;
$questrial: "Questrial", sans-serif;
$nunito: "Nunito", sans-serif;

.label {
  font-family: $nunito;
  font-size: 15px;
  color: $label-color;
  font-weight: bold;
}

.statNumber {
  font-family: $questrial;
  font-size: 40px;
  color: $label-color;
  font-weight: bold;
  text-align: center;
  &--small {
    font-size: 25px;
    text-align: center;
  }
}

.caption {
  font-family: $raleway;
  font-size: 15px;
  color: $label-color;
  font-weight: lighter;
  text-align: center;
  &--small {
    font-size: 12px;
    text-align: center;
  }
}

.small-label {
  font-family: $raleway;
  font-size: 13px;
  color: $sub-menu-item-color;
  height: 40px;
}

.small-stat-no {
  font-family: $questrial;
  font-size: 18px;
  color: $black;
}
