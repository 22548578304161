%nav-margin {
  margin: 10px;
  display: block !important;
  @include breakpoint(sp) {
    display: none !important;
  }
}

.navbar {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;

  &__drawer {
    @extend %nav-margin;
    display: none !important;
    @include breakpoint(sp) {
      display: block !important;
    }
  }
  &__items {
    @extend %nav-margin;
  }
  &__logout {
    @extend %nav-margin;
  }
}
