.map_small{
	z-index: 1;
	position: relative;
	left: -25px;
}

.span_component{
	z-index: 1;
	position: relative;
	top: -7px;
}

.details{
	padding-bottom: 10px;
}

.modal_icon {
	font-size: 25px;
	margin-right: 30px;
	position: relative;
	bottom: -5px;
}

/*.col-container {
  display: table;
  width: 100%;
}*/

/*.col {
  display: table-cell;
  padding: 16px;
}*/