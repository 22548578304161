.pageTitle {
  font-size: 30px;
  font-family: "questrial", sans-serif;
  color: $black;
  margin-bottom: 25px;
}

.w100 {
  width: 100%;
}

.hrStyled {
  border-top: 0.7px solid $light-dark;
}

.m-t-10px {
  margin-top: 10px !important;
}

.m-t-15px {
  margin-top: 15px !important;
}

.m-t-20px {
  margin-top: 20px !important;
}

.m-t-2 {
  margin-top: 2% !important;
}

.m-t-5 {
  margin-top: 5% !important;
}

.m-t-10 {
  margin-top: 10% !important;
}
.m-t-20 {
  margin-top: 20% !important;
}

.panel {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid $light-dark;
  @include breakpoint(sp) {
    margin-bottom: 16px;
  }
  &__title {
    margin-bottom: 16px;
  }
  &--small {
    display: flex;
    flex-direction: column;
  }
  &__total-user {
    width: 30%;
    @include breakpoint(sp) {
      width: 100%;
    }
  }
}

.trend {
  width: 100%;
  display: flex;
  font-family: "Nunito", sans-serif;
  color: $white;
  font-size: 15px;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
  span {
    border-radius: 10px;
    padding: 2px 5px;
    display: flex;
    width: 60px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
  }
  &--down {
    background: $red;
  }
  &--up {
    background: $green;
  }
  &--left {
    justify-content: flex-start;
  }
}

.download-trend-panel {
  margin-top: 10%;
}

.actionGroup {
  display: flex;
  justify-content: flex-start;
  margin: 16px 0;
  align-items: center;
}

.spinner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-container {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid $light-dark;
  @include breakpoint(sp) {
    margin-bottom: 16px;
  }
}

pre{
  counter-reset: line;
  padding: 5px;
  border-radius: 3px;
  word-break: break-word;
}

code{
  counter-increment: line;
}

code:before{
  content: counter(line);
  padding-right: 1em;
  width: 1.5em;
  text-align: right;
  color: white;
  opacity: 0.5;
}

thead[class*="ant-table-thead"] th{
  background-color: rgb(247, 247, 247) !important;
}

.table-index-number {
  background: rgb(247, 247, 247);
  color:rgba(5, 5, 5, 0.418);
}

td.ant-table-selection-column {
  background-color:  rgb(247, 247, 247) !important;
}
