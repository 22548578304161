.pageTitle {
  font-size: 30px;
  font-family: "questrial", sans-serif;
  color: #1f1f1f;
  margin-bottom: 25px;
}

.w100 {
  width: 100%;
}

.hrStyled {
  border-top: 0.7px solid rgba(112, 112, 112, 0.18);
}

.m-t-10px {
  margin-top: 10px !important;
}

.m-t-15px {
  margin-top: 15px !important;
}

.m-t-20px {
  margin-top: 20px !important;
}

.m-t-2 {
  margin-top: 2% !important;
}

.m-t-5 {
  margin-top: 5% !important;
}

.m-t-10 {
  margin-top: 10% !important;
}

.m-t-20 {
  margin-top: 20% !important;
}

.panel {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid rgba(112, 112, 112, 0.18);
}
@media (max-width: 768px) {
  .panel {
    margin-bottom: 16px;
  }
}
.panel__title {
  margin-bottom: 16px;
}
.panel--small {
  display: flex;
  flex-direction: column;
}
.panel__total-user {
  width: 30%;
}
@media (max-width: 768px) {
  .panel__total-user {
    width: 100%;
  }
}

.trend {
  width: 100%;
  display: flex;
  font-family: "Nunito", sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
}
.trend span {
  border-radius: 10px;
  padding: 2px 5px;
  display: flex;
  width: 60px;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}
.trend--down {
  background: #ed7777;
}
.trend--up {
  background: #008e4c;
}
.trend--left {
  justify-content: flex-start;
}

.download-trend-panel {
  margin-top: 10%;
}

.actionGroup {
  display: flex;
  justify-content: flex-start;
  margin: 16px 0;
  align-items: center;
}

.spinner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-container {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(112, 112, 112, 0.18);
}
@media (max-width: 768px) {
  .checkbox-container {
    margin-bottom: 16px;
  }
}

pre {
  counter-reset: line;
  padding: 5px;
  border-radius: 3px;
  word-break: break-word;
}

code {
  counter-increment: line;
}

code:before {
  content: counter(line);
  padding-right: 1em;
  width: 1.5em;
  text-align: right;
  color: white;
  opacity: 0.5;
}

thead[class*=ant-table-thead] th {
  background-color: #f7f7f7 !important;
}

.table-index-number {
  background: #f7f7f7;
  color: rgba(5, 5, 5, 0.418);
}

td.ant-table-selection-column {
  background-color: #f7f7f7 !important;
}

.label {
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  color: #29313c;
  font-weight: bold;
}

.statNumber {
  font-family: "Questrial", sans-serif;
  font-size: 40px;
  color: #29313c;
  font-weight: bold;
  text-align: center;
}
.statNumber--small {
  font-size: 25px;
  text-align: center;
}

.caption {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  color: #29313c;
  font-weight: lighter;
  text-align: center;
}
.caption--small {
  font-size: 12px;
  text-align: center;
}

.small-label {
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  color: #828fa2;
  height: 40px;
}

.small-stat-no {
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  color: #1f1f1f;
}

.form-row {
  margin-bottom: 20px;
}

.mute {
  color: #484848;
  font-size: 10px;
  opacity: 0.8;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
}

.image {
  width: 300px;
}

.navbar__logout, .navbar__items, .navbar__drawer {
  margin: 10px;
  display: block !important;
}
@media (max-width: 768px) {
  .navbar__logout, .navbar__items, .navbar__drawer {
    display: none !important;
  }
}

.navbar {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
.navbar__drawer {
  display: none !important;
}
@media (max-width: 768px) {
  .navbar__drawer {
    display: block !important;
  }
}
.sidebar__logo {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 64px;
  color: #484848;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar__logo a {
  display: inline;
  color: unset;
}
.sidebar__logoImg {
  margin-right: 0.5rem;
}
.sidebar__sub-title {
  font-family: "Raleway", sans-serif;
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.sidebar__sub .ant-menu-item {
  font-family: "Questrial", sans-serif;
  font-size: 0.9375rem !important;
  color: #828fa2;
}

.sidebar__main .ant-layout-sider-trigger {
  border-top: solid 1px #cfcfcf;
}

.sidebar__main .ant-menu-root {
  margin-top: 20px !important;
}

.sidebar__sub .ant-menu-item-active,
.ant-menu-submenu-selected {
  color: #008e4c !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: green !important;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(#008e4c), to(#008e4c));
  background: linear-gradient(to right, #008e4c, #008e4c) !important;
}

.sidebar__sub .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
li.ant-menu-item.ant-menu-item-selected {
  background-color: #7bd1aa !important;
  color: #008e4c;
}

.sidebar__sub .ant-menu-inline .ant-menu-selected::after,
.sidebar__sub .ant-menu-inline .ant-menu-item-selected::after {
  border-color: #008e4c !important;
}

.ant-menu-item-group {
  margin-left: 1em;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #7bd1aa !important;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: inherit !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: unset !important;
}

a:hover,
a:focus,
a:active {
  text-decoration: none !important;
  color: inherit !important;
}

.components {
  font-family: "Raleway", sans-serif;
  color: rgba(0, 0, 0, 0.65);
}

.colors__item {
  padding: 10px;
}

.c1 {
  background: #fff;
}

.c2 {
  background: #f2f2f2;
}

.c3 {
  background: #707070;
}

.c4 {
  background: #484848;
}

.c5 {
  background: #1f1f1f;
}

.c6 {
  background: #29313c;
}

.c7 {
  background: #008e4c;
}

.c8 {
  background: #7bd1aa;
}

.c9 {
  background: #828fa2;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form {
  max-width: 300px;
  width: 500px;
}

.login-form-button {
  width: 100%;
}

.logo {
  width: 100%;
}

.login__title {
  font-family: "Questrial", sans-serif;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
  color: #fff;
}

.loginSpinner {
  text-align: center;
}

.errorDescription {
  color: red;
  text-align: left;
  font-family: "Questrial", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.panel__stats {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .panel__stats {
    display: block;
    text-align: center;
  }
}

.panel__breakdown {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  margin-left: 10%;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .panel__breakdown {
    display: inline;
  }
}
.panel__breakdown--item {
  padding: 10px;
  width: 100%;
}

.panel__title-sec {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  color: #000;
  font-weight: bold;
}

.userPerUserTypeCont {
  width: 100%;
  height: 500px;
}

.downloads .panel {
  margin: 8px 0;
}

.isFullScreen {
  width: 100vw;
  height: 100vh;
}

.fullscreen-title {
  display: flex;
  justify-content: space-between;
}

.imageFullscreen {
  height: 100vh;
}

.imageContainer {
  position: relative;
}

.fullscreenButton {
  position: absolute;
  top: 8px;
  right: 16px;
}