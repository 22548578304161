.components {
  font-family: "Raleway", sans-serif;
  color: $title-text;
}

.colors__item {
  padding: 10px;
}

.c1 {
  background: $white;
}
.c2 {
  background: $grey;
}
.c3 {
  background: $dark-grey;
}
.c4 {
  background: $logo-grey;
}
.c5 {
  background: $black;
}
.c6 {
  background: $label-color;
}
.c7 {
  background: $green;
}
.c8 {
  background: $light-green;
}
.c9 {
  background: $sub-menu-item-color;
}
