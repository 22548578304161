.sidebar {
  &__logo {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: bold;
    height: 64px;
    color: $logo-grey;
    letter-spacing: 2px;
    @include flexCenterAll;
    a {
      display: inline;
      color: unset;
    }
  }
  &__logoImg {
    margin-right: 0.5rem;
  }
  &__sub-title {
    font-family: "Raleway", sans-serif;
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 1px;
  }
}

.sidebar__sub .ant-menu-item {
  font-family: "Questrial", sans-serif;
  font-size: 0.9375rem !important;
  color: $sub-menu-item-color;
}

.sidebar__main .ant-layout-sider-trigger {
  border-top: solid 1px #cfcfcf;
}

.sidebar__main .ant-menu-root {
  margin-top: 20px !important;
}

.sidebar__sub .ant-menu-item-active,
.ant-menu-submenu-selected {
  color: $green !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: green !important;
}

.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($green),
    to($green)
  );
  background: linear-gradient(to right, $green, $green) !important;
}
.sidebar__sub .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
li.ant-menu-item.ant-menu-item-selected {
  background-color: $light-green !important;
  color: $green;
}

.sidebar__sub .ant-menu-inline .ant-menu-selected::after,
.sidebar__sub .ant-menu-inline .ant-menu-item-selected::after {
  border-color: $green !important;
}

.ant-menu-item-group {
  margin-left: 1em;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: $light-green !important;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: inherit !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: unset !important;
}

a:hover,
a:focus,
a:active {
  text-decoration: none !important;
  color: inherit !important;
}
