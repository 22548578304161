.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-form {
  max-width: 300px;
  width: 500px;
}

.login-form-button {
  width: 100%;
}

.logo {
  width: 100%;
}

.login__title {
  font-family: $questrial;
  color: $title-text;
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
  color: #fff;
}

.loginSpinner {
  text-align: center;
}

.errorDescription {
  color: red;
  text-align: left;
  font-family: $questrial;
  font-weight: bold;
  font-size: 16px;
}
