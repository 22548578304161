.Countdown{
  margin: 10px auto;
  padding-bottom: 20px;
}

.Countdown-col{
  display: block;
}

.Countdown-col-element{
  display: block;
  margin: 0 0px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 25px;
}