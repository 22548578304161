// font variables

$roboto-slab: "Roboto Slab", serif;
$roboto: "Roboto", sans-serif;
$poppins: "Poppins", sans-serif;

// colors

$white: #fff;
$grey: #f2f2f2;
$dark-grey: #707070;
$logo-grey: #484848;
$black: #1f1f1f;
$light-dark: rgba(112, 112, 112, 0.18);
$label-color: #29313c;
$green: #008e4c;
$light-green: #7bd1aa;
$sub-menu-item-color: #828fa2;
$red: #ed7777;
$title-text: rgba(0, 0, 0, 0.65);
